import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import SpiderGraphic from '../../../../spider_graphic';
import EvaluationLink from './evaluation_link';
import TrailStatisticMessage from './trail_statistic_message';

import {
  runShape,
  spiderGraphShape,
  trailStatisticShape
} from '../../../shapes/entities';

const SubTestsStatisticsCard = ({
  run,
  trailStatistic,
  subTestsStatistics,
  scoreClassName
}) => {
  return (
    <article className="l-grid__item c-results-card qa-sub-tests-statistics-card">
      <div className="l-flex-grid__intro l-flex-grid__intro-ranking">
        <header className="t-margin--bottom">
          <h2 className="o-h2 t-text-weight--bold">
            <FormattedMessage id="runs.results.sub_tests_statistics_card.header" />
          </h2>
        </header>
        <div>
          <SpiderGraphic
            input={[
              {
                type: 'runSubTestStatistics',
                opacity: 0.5,
                lineWidth: 4,
                statistics: subTestsStatistics
              }
            ]}
            chartStyle={{ height: '14.75em' }}
            radarOptionOverrides={{ radius: '60%' }}
          />
          <div className="t-padding--top">
            <p className="t-no-margin t-line-height--tight t-text-align--center qa-trail-statistic-message">
              <TrailStatisticMessage
                scoreClassName={scoreClassName}
                trailStatistic={trailStatistic}
              />
            </p>
          </div>
          <div className="l-flex-grid__end">
            <EvaluationLink run={run} />
          </div>
        </div>
      </div>
    </article>
  );
};

SubTestsStatisticsCard.propTypes = {
  run: runShape.isRequired,
  trailStatistic: trailStatisticShape.isRequired,
  subTestsStatistics: spiderGraphShape.isRequired,
  scoreClassName: PropTypes.string.isRequired
};

export default SubTestsStatisticsCard;
